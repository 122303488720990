<script lang="ts">
	const year = new Date().getFullYear();
</script>
<footer class="footer">
	<div class="container-fluid">
		<div  class="d-flex justify-content-between" >
			<p class="mb-0">
				© Copyright {year} <a href="http://www.scorius.nl" class="text-muted">Scorius</a>
			</p>
			<img src="img/scorius-logo-grey.png" alt="Scorius logo" width="140"/>
		</div>
	</div>
</footer>