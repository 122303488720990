import { get } from 'svelte/store';

import {
    allReportDataForFetchedGroups,
    availableReports,
    dashboardFocusReportSlug,
    heatmapMode,
    historicBenchmarkMode,
    historicBenchmarkSlug,
    organizationalUnitsSelected,
    subGroupsSelected
} from '../stores/dataStore';

import * as d3 from 'd3';
import {
    createIDStringForElement,
    getAllKeysForGroupAndSubgroups,
    getDisplayReportName,
    getGroupNameForKey,
    scaleOrginalValueToDesiredScale,
    scaleValueToPercentage
} from './formatDataHelperFunctions.js';


import { allTranslations, currentLanguage } from '../stores/languageStore.js';

// Read me
// organizationalUnitsSelected & subGroupsSelected are used as params to determine which data to show
// all data is already present in the store (allReportDataForFetchedGroups), so we just need to filter it


export function generateGridContent(report, groupsToShowInChart, subGroupsToShowInChart, constructsToShowInChart) {
    // console.log('generateGridContent for ',report, groupsToShowInChart, subGroupsToShowInChart, constructsToShowInChart);
    // console.log('using data source: ', get(allReportDataForFetchedGroups));
    let allReportDataForFetchedGroupsLocalObject = get(allReportDataForFetchedGroups);
    // console.log('using data source for report: ', allReportDataForFetchedGroupsLocalObject[report]);
    //console.log(get(organizationalUnitsSelected));
    //console.log(get(subGroupsSelected));
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // Calculate the width in pixels for your column - for example, 30% of the screen width
    let calculatedMinWidthTopic = screenWidth * 0.05; // This is 30%
    let calculatedMinWidthQuestion = screenWidth * 0.26; // This is 30%
    let calculatedMinWidthVisual = screenWidth * 0.07; // This is 30%

    let currentLanguageToShowInTable = get(currentLanguage);
    // console.log('currentLanguageToShowInTable', currentLanguageToShowInTable['code']);


    let columnDefs = [
        {
            field: 'Construct',
            headerName: allTranslations['grid']['construct'][currentLanguageToShowInTable['code']],
            enableRowGroup: true,
            minWidth: calculatedMinWidthTopic,
            cellStyle: { 'text-align': 'left' },
            valueFormatter: capitalFormatter,
            pinned: 'left',

        },
        {
            field: 'Topic',
            headerName: allTranslations['grid']['onderwerp'][currentLanguageToShowInTable['code']],
            enableRowGroup: true,
            minWidth: calculatedMinWidthTopic,
            cellStyle: { 'text-align': 'left' },
            valueFormatter: capitalFormatter,
            pinned: 'left',

        },
        {
            field: 'Question',
            headerName: allTranslations['grid']['vraag'][currentLanguageToShowInTable['code']],
            minWidth: calculatedMinWidthQuestion,
            cellStyle: { 'text-align': 'left' },
            valueFormatter: capitalFormatter,
            pinned: 'left',
        },
        {
            field: 'Visual',
            headerName: allTranslations['grid']['visueel'][currentLanguageToShowInTable['code']],
            minWidth: calculatedMinWidthVisual,
            cellRenderer: visualRenderer,
            pinned: 'left',
            sortable: false,
        }
    ];
    if (!constructsToShowInChart.includes('alle_resultaten')) {
        columnDefs.splice(0, 1);
    }


    //for group
    for (let i = 0; i < groupsToShowInChart.length; i++) {
        let group = groupsToShowInChart[i];
        columnDefs.push({
            field: group,
            headerName: getGroupNameForKey(group),
            valueFormatter: roundFormatter,
            groupId: group,
            minWidth: 100,
            cellRenderer: cellRendererGrid,
            cellClass: (params: any) => {
                if (get(heatmapMode)) {
                    const value: number = Number(roundFormatter(params));
                    if (!Number.isNaN(value)) {
                        return heatMapClassScale(value);
                    }
                }
            },
            cellStyle: () => {
                return { 'text-align': 'center' };
            }

        });

        for (let j = 0; j < subGroupsToShowInChart.length; j++) {
            let subGroup = subGroupsToShowInChart[j];
            columnDefs.push({
                field: group + '_AND_' + subGroup,
                headerName: getGroupNameForKey(group + '_AND_' + subGroup),
                groupId: group + '_AND_' + subGroup,
                valueFormatter: roundFormatter,
                cellRenderer: cellRendererGrid,
                cellClass: (params: any) => {
                    if (get(heatmapMode)) {
                        const value: number = Number(roundFormatter(params));
                        if (!Number.isNaN(value)) {
                            return heatMapClassScale(value);
                        }
                    }
                },
                cellStyle: () => {
                    return { 'text-align': 'center' };
                }
            });
        }
    }

    //End columnGeneration, now rows
    // console.log('columnDefs', columnDefs);

    let rowData = []
    let rowDetails = {}
    if (allReportDataForFetchedGroupsLocalObject[report]['details']['constructs']) {
        rowDetails = allReportDataForFetchedGroupsLocalObject[report]['details']['constructs'];
    } else {
        console.error('no details found in allReportDataForFetchedGroupsLocalObject');
        //TODO launch fetch details for this report
    }
    // console.log('rowDetails', rowDetails);
    // Convert the object into an array of [key, value] pairs
    let constructs = Object.entries(rowDetails);
    let filteredConstructs = constructs.filter(entry => entry[1].show);
    // Sort the filtered array based on 'orderBy'
    let sortedConstructs = filteredConstructs.sort((a, b) => a[1].orderBy - b[1].orderBy);
    // Convert the sorted array back into an object
    let objectWithSortedConstructs = Object.fromEntries(sortedConstructs);
    // console.log(objectWithSortedConstructs);

    let constructsToShowInTable = Object.keys(objectWithSortedConstructs);
    //  console.log('constructsToShowInTable', constructsToShowInTable);

    constructsToShowInTable.forEach(construct => {
        //if constructsToShowInChart contains 'alle_resultaten'go on, else if construct is not in constructsToShowInChart, skip
        if (constructsToShowInChart.includes('alle_resultaten') || constructsToShowInChart.includes(construct)) {
            let constructDetails = objectWithSortedConstructs[construct];
            //console.log(constructDetails);

            let rowDataElementConstruct: string = constructDetails.name[currentLanguageToShowInTable['code']];


            let constructTopics = constructDetails['topics'];
            let _constructTopicsArray = Object.keys(constructTopics).map(k =>  constructTopics[k] );
            _constructTopicsArray.sort((a,b) => a.orderBy - b.orderBy);
            constructTopics = _constructTopicsArray.reduce(function(acc, cur, i) {
                acc[i] = cur;
                return acc;
              }, {});
            
            let constructTopicsToShowInTable = Object.keys(constructTopics);


            constructTopicsToShowInTable.forEach(topic => {
                let topicDetails = constructTopics[topic];
                //console.log(topicDetails);
                let rowDataElementTopic: string = topicDetails.name[currentLanguageToShowInTable['code']];


                let constructItems = topicDetails['items'];
                let _constructItemsArray = Object.keys(constructItems).map(k =>  constructItems[k] );
                _constructItemsArray.sort((a,b) => a.orderBy - b.orderBy);
                constructItems = _constructItemsArray.reduce(function(acc, cur, i) {
                    acc[i] = cur;
                    return acc;
                }, {});

                let constructItemsToShowInTable = Object.keys(constructItems);
                if (constructItemsToShowInTable.length !== 0) {
                    //  console.log('constructItemsToShowInTable', constructItemsToShowInTable);
                    constructItemsToShowInTable.forEach(item => {
                        let itemDetails = constructItems[item];

                        if (itemDetails.show) {
                            let rowDataElementItem: string = itemDetails.text[currentLanguageToShowInTable['code']];
                            let rowId: string = 'rowForItem_' + itemDetails.id;
                            let rowObject =
                            {
                                id: rowId,
                                Construct: rowDataElementConstruct,
                                Topic: rowDataElementTopic,
                                Question: rowDataElementItem,
                                Visual: 'y',
                            }

                            for (let i = 0; i < groupsToShowInChart.length; i++) {
                                let group = groupsToShowInChart[i];

                                let groupScore = 'n/a';  // Default value
                                const reportData = allReportDataForFetchedGroupsLocalObject[report];
                                groupScore = getDataForAGroup(reportData, group, itemDetails);

                                rowObject[group] = groupScore;


                                for (let j = 0; j < subGroupsToShowInChart.length; j++) {
                                    let subGroup = subGroupsToShowInChart[j];
                                    let groupKey = group + '_AND_' + subGroup
                                    let groupScore = 'n/a';
                                    groupScore = getDataForAGroup(reportData, groupKey, itemDetails);

                                    rowObject[groupKey] = groupScore;
                                }


                            }
                            rowData.push(rowObject);
                        }


                    });
                } else {
                    //  console.log('constructItemsToShowInTable empty, skipping:', topic);
                }
            })

        } else {
            //  console.log('construct not in constructsToShowInChart, skipping:', construct);
        }


    });



    // let rowData = [
    //     { construct: 'test1', topic: 'waardering', question: "xx", score:Math.random()  },
    //     { construct: 'test2', topic: 'waardering', question: "xx", score:Math.random()  },
    //     { construct: 'test3', topic: 'waardering', question: "xx", score:Math.random()  },
    // ]

    return [columnDefs, rowData];
}


function getDataForAGroup(reportData, group, itemDetails) {

    //console.log('getDataForAGroup',group,itemDetails)

    let groupScore = "geen data";
    if (reportData) {
        const groupData = reportData[group];
        if (groupData) {
            // console.log('groupData found', groupData)
            const itemData = groupData[itemDetails.id];
            if (itemData) {
                if (itemData['responseType'] === 'LIKERT_7_POS' || itemData['responseType'] === 'LIKERT_7_NEG' || itemData['responseType'] === 'CHOICE_LIKERT_7_NA') {
                    let orginalGroupScore = itemData['mean'];

                    groupScore = scaleOrginalValueToDesiredScale(JSON.stringify(itemDetails.id), orginalGroupScore);

                    if (groupScore === null || groupScore === 'null') {
                        groupScore = "geen data";
                    }
                    //   console.log(groupScore, orginalGroupScore);
                } else if(itemData['responseType'] === 'CHOICE_GRADE_10') {
                    groupScore = itemData['score'];
                } else {
                    // groupScore = itemData['count'];
                    groupScore = itemData['responseType'];
                }

            }
        } else {
            //   console.log('groupData not found');
            //   console.log(reportData);
        }
    } else {
        //  console.log('reportData not found');
        //  console.log(reportData);
    }
    return groupScore;
}

function capitalFormatter(params) {
    // Ensure there is a value and it's a string
    if (params && typeof params.value === 'string') {
        // Convert the first character to uppercase, and leave the rest of the string unchanged
        return params.value.charAt(0).toUpperCase() + params.value.slice(1);
    } else {
        // If the value is not a string, just return it as is
        return params.value;
    }
}
function roundFormatter(params) {
    if (typeof params.value === 'number') {
        return params.value.toFixed(1);
    }
    return params.value; // or return some default value or null
}
const heatMapColorScale: d3.ScaleLinear<string, string> = d3.scaleLinear<string, string>()
    .domain([1, 5.95, 6, 6.95, 7, 7.95, 8, 10])
    .range([
        'rgba(215, 48, 39, 0.5)',   // Deep red for < 6
        'rgba(215, 48, 39, 0.5)',   // Continue deep red up to 6
        'rgba(252, 174, 145, 0.5)', // Orange at 6
        'rgba(252, 174, 145, 0.5)', // Continue orange up to just before 7
        'transparent',              // Neutral for >= 7 and < 8
        'transparent',              // Continue neutral up to just before 8
        'rgba(26, 152, 80, 0.5)',   // Green at >= 8
        'rgba(0, 104, 55, 0.5)'     // Deeper green towards 10
    ]);

const heatMapClassScale = (value: number): string => {
    if (value < 6) {
        return "cell-red";
    }

    if (value >= 6 && value < 7) {
        return "cell-orange";
    }

    if (value >= 7 && value < 8) {
        return "cell-transparent";
    }

    return "cell-green";
};

function roundToOneDecimal(value: number): number {
    return Math.round(value * 10) / 10;
}

function getColor(value: number): string {
    let roundedScore: number = roundToOneDecimal(value);
    let color: string = heatMapColorScale(roundedScore);
    return color;
}

function visualRenderer(params) {
    // console.log('visualRenderer',params.data);
    let circlesHTML = '';
    let rawKeysToConsider = Object.keys(params.data).filter(
        (key) => !['id', 'Construct', 'Topic', 'Question'].includes(key)
    );
    //now check with $organizationalUnitsSelected to filter out all stuff
    let lookForTheseOrganizationalUnits = get(organizationalUnitsSelected);
    let lookForTheseSubgroups = get(subGroupsSelected);

    let keysToConsider = getAllKeysForGroupAndSubgroups(lookForTheseOrganizationalUnits, lookForTheseSubgroups);
    // console.log('keysToConsider',keysToConsider);


    keysToConsider.forEach((key, index) => {
        //  console.log("key, params.data")
        //  console.log(key, params.data)

        let idString = createIDStringForElement(key);
        let idStringRow = createIDStringForElement(params.data['id']);

        //   console.log(idString, idStringRow)

        //let value = 1;
        const rowId = params.data.id.replace("rowForItem_", "");
        let value = scaleValueToPercentage(rowId, params.data[key]);

        let color = visualRendererColorScale(index + 1); // Use the index to get the color
        let classHidden = 'hidden';
        if (value > 0) {
            classHidden = ''
        }

        // let hoverMessage=getGroupAndSubgroupNameForKey(key);
        let hoverMessage = getGroupNameForKey(key);

        circlesHTML += `<span id=${idString}_${idStringRow}_circle class="${idString}_circle circle ${classHidden}" style='position: absolute; top: 50%; transform: translateY(-50%); width: 15px; height: 15px; border-radius: 50%; background-color: ${color}; left: ${value}%;' title='${hoverMessage}'></span>`;
    });

    return `
    <div style='position: relative; width: 100%; height: 100%;'>
        <div style='position: absolute; top: 50%; transform: translateY(-50%); width: 100%; height: 2px; background-color: #ccc;'></div>
        ${circlesHTML}
    </div>`;
}

const visualRendererColorScale = d3
    .scaleOrdinal()
    .domain([1, 2, 3])
    .range([
        '#F8766D',
        '#CD9600',
        '#7CAE00',
        '#00BE67',
        '#00BFC4',
        '#00A9FF',
        '#C77CFF',
        '#FF61CC'
    ]);



function cellRendererGrid(params) {
    // console.log(params)
    if (params.value === 'geen data') {
        return '<i class="fa fa-minus"></i>';
    } else if (typeof params.value === 'string') {
        return '<i class="fa fa-bar-chart"></i>';
    }

    let currentDisplayedScore = params.value
    const rowId = params.data.id.replace("rowForItem_", "");
    // console.log('Row Id: ', rowId);
    // Getting the column id
    const colId = params.column.userProvidedColDef.groupId;
    //  console.log('Column Id: ', colId);

    let htmlStringWithBadges = ''
    let arrayWithSlugsToMakeHistoricBadges = [];
    // Get .slug from the first 'standardAmountOfReportsToFetch' reports
    //Option 1: only the standardAmountOfReportsToFetch amount of reports
    // for (let i = 0; i < get(standardAmountOfReportsToFetch); i++) {
    //     if (i < get(availableReports).length) {
    //         let slugForBenchmark = get(availableReports)[i].slug; // Accessing the 'slug' property
    //         arrayWithSlugsToMakeHistoricBadges.push(slugForBenchmark);
    //     }
    // }
    //option 2: all reports
    let allReportsThatAreAvailable = get(availableReports);
    // console.log('allReportsThatAreAvailable',allReportsThatAreAvailable)
    allReportsThatAreAvailable.forEach(report => {
        arrayWithSlugsToMakeHistoricBadges.push(report.slug);
    })
    //  console.log('arrayWithSlugsToMakeHistoricBadges',arrayWithSlugsToMakeHistoricBadges)


    arrayWithSlugsToMakeHistoricBadges.forEach(slug => {
        if (slug !== get(dashboardFocusReportSlug)) {
            makeBadge(slug)
        }

    })


    function makeBadge(slug) {
        //console.log('makeBadge',slug, rowId, colId, 'compare to slug', get(dashboardFocusReportSlug))
        let allReportDataForFetchedGroupsLocal = get(allReportDataForFetchedGroups);

        let slugUsedForValueDisplayedInTable = get(dashboardFocusReportSlug);
        let slugUsedForThisBadge = slug;

        let dataDisplayedInTable = allReportDataForFetchedGroupsLocal[slugUsedForValueDisplayedInTable]?.[colId]?.[rowId];
        let dataUsedForInBadge = allReportDataForFetchedGroupsLocal[slugUsedForThisBadge]?.[colId]?.[rowId];

        if (!dataDisplayedInTable) {
            // console.error('dataDisplayedInTable not found', rowId, colId, slugUsedForValueDisplayedInTable, slugUsedForThisBadge)
            return '';
        }

        //     console.log(dataDisplayedInTable)
        //    console.log(dataUsedForInBadge)
        // let resultsToBaseBadgeOn = getallReportDataForSelectedOrganizations[slug]?.value?.data?.results;
        let constructDirection: string = 'gunstig';
        if (dataDisplayedInTable['responseType'] === 'LIKERT_7_POS') {
            constructDirection = 'gunstig';
        } else if (dataDisplayedInTable['responseType'] === 'LIKERT_7_NEG') {
            constructDirection = 'ongunstig';
        }
        else {
            constructDirection = 'choiceQuestion';
            return '';
        }

        let badgeClasses: string = 'badge badge-soft-nochange custom-grid-badge ';
        let formattedDifferenceScore: string = '0';
        let hiddenClass = 'hidden';

        let currentHistoricSlugToDisplay = get(historicBenchmarkSlug);

        // Dit stuk code klopt niet, de eerste span krijgt geen hidden waardoor de score niet in het midden staat.
        if (currentHistoricSlugToDisplay === slugUsedForThisBadge) {
            // TODO Stijn wat is de juiste oplossing hiervoor? Deze berekening is duur vanwege de subscribe in een redraw denk ik?
            historicBenchmarkMode.subscribe((mode) => {
                if (!mode) {
                    hiddenClass = '';
                }
            });
        }

        let textHover = getDisplayReportName(slugUsedForThisBadge);

        let thereIsHistoricData = true;
        if (!dataUsedForInBadge || dataUsedForInBadge?.['mean'] === null || dataUsedForInBadge?.['mean'] === undefined) {
            thereIsHistoricData = false;
            //  console.log('thereIsHistoricData',thereIsHistoricData)
        }
        let thereIsCurrentData = true;
        if (dataDisplayedInTable['mean'] === null || dataDisplayedInTable['mean'] === undefined) {
            thereIsCurrentData = false;
        }


        if (!thereIsCurrentData) {
            formattedDifferenceScore = allTranslations['grid']['noData'][get(currentLanguage)['code']];
        } else if (!thereIsHistoricData) {
            formattedDifferenceScore = allTranslations['grid']['noData'][get(currentLanguage)['code']];
        } else {

            // Use optional chaining and provide a default value (e.g., 0) if 'mean' is not available
            let scaledDataDisplayedInTable = scaleOrginalValueToDesiredScale(rowId, dataDisplayedInTable?.mean ?? 0);
            let scaledDataUsedForInBadge = scaleOrginalValueToDesiredScale(rowId, dataUsedForInBadge?.mean ?? 0);
            // console.log('scaledDataDisplayedInTable',scaledDataDisplayedInTable)
            // console.log('scaledDataUsedForInBadge',scaledDataUsedForInBadge)
            let differenceScore = Math.round((scaledDataDisplayedInTable - scaledDataUsedForInBadge) * 10) / 10;

            if (differenceScore <= -0.3) {
                badgeClasses += 'cell-trend-red';
                formattedDifferenceScore = differenceScore.toString();
            } else if (differenceScore >= 0.3) {
                badgeClasses += 'cell-trend-green';
                formattedDifferenceScore = '+' + differenceScore.toString();
            } else { // This covers the case where differenceScore is between -0.3 and +0.3, inclusive
                badgeClasses += 'cell-trend-transparent';
                // For scores exactly equal to 0, format specifically; otherwise, format normally
                if (differenceScore === 0) {
                    formattedDifferenceScore = '\xa0' + '0.0'; // 
                } else {
                    // Use a '+' sign for positive numbers for consistency in display
                    formattedDifferenceScore = differenceScore > 0 ? '+' + differenceScore.toString() : differenceScore.toString();
                }
            }

        }

        if (!thereIsCurrentData) {
            htmlStringWithBadges += `
            <span class="${badgeClasses} me-2 ml-2 heatmapBenchmark_org ${hiddenClass} ${slugUsedForThisBadge}" title="${textHover}"> 
                <i class="mdi mdi-arrow-bottom-right"></i>
                ${formattedDifferenceScore}
            </span>`;

        } else {
            if (!get(historicBenchmarkMode)) {
                htmlStringWithBadges += `
                <span class="${badgeClasses} me-2 ml-2 heatmapBenchmark_org ${hiddenClass} ${slugUsedForThisBadge}" title="${textHover}">
                    <i class="mdi mdi-arrow-bottom-right"></i>
                    ${formattedDifferenceScore}
                </span>`;
            } else {
                htmlStringWithBadges += `
                <span class="${badgeClasses} me-2 ml-2 heatmapBenchmark_org ${hiddenClass} ${slugUsedForThisBadge}" title="${textHover}">
                    <i class="mdi mdi-arrow-bottom-right"></i>
                </span>`;
            }
        }

    }

    let htmlElement =
        `
    <div style='position: relative; width: 100%; height: 100%;' class='custom-grid-cell'>
        ${params.valueFormatted} ${htmlStringWithBadges}
    </div>
    `

    //console.log(htmlElement)
    return htmlElement;

}

function cellRendererResponseGrid(params) {
    //  console.log('cellRendererResponseGrid',params)
    if (params.value === 0) {
        return '<i class="fa fa-minus"></i>';
    } else if (typeof params.value === 'string') {
        return '<i class="fa fa-minus"></i>';
    }

    let currentDisplayedScore = params.value + '%'
    return `<span class='custom-grid-cell'>${currentDisplayedScore}</span>`;


}

export function generateGridContentForResponse(reports, groupsToShowInChart) {


    //console.log('generateGridContentForResponse for ', reports, groupsToShowInChart);
    //sort the reports on slugname to make sure they are in the right order
    reports.sort();

    let allReportDataForFetchedGroupsLocalObject = get(allReportDataForFetchedGroups);
    let currentLanguageToShowInTable = get(currentLanguage);
    // console.log('currentLanguageToShowInTable', currentLanguageToShowInTable['code']);


    let columnDefs = [
        {
            field: 'group',
            headerName: allTranslations['grid']['group'][currentLanguageToShowInTable['code']],
            enableRowGroup: true,
            cellStyle: { 'text-align': 'left' },
            valueFormatter: capitalFormatter,
            pinned: 'left',
        }

    ];

    reports.forEach(report => {

        columnDefs.push({
            field: report,
            headerName: getDisplayReportName(report),
            valueFormatter: roundFormatter,
            cellRenderer: cellRendererResponseGrid,
            groupId: report,
        });

    })

    let rowData = []

    groupsToShowInChart.forEach(group => {

        let name = getGroupNameForKey(group);
        let rowObject =
        {
            group: name,
        }

        reports.forEach(report => {
            const reportData = allReportDataForFetchedGroupsLocalObject[report]?.['group_summary'];
            // Safely access 'invited' and 'response', and provide default values (e.g., 0) if they are not available
            let invited = reportData?.[group]?.['invited'] ?? 0;
            let response = reportData?.[group]?.['response'] ?? 0;
            // Calculate responsePercentage, handling the case where 'invited' is 0 to avoid division by zero
            let responsePercentage = invited > 0 ? Math.round((response / invited * 100) * 10) / 10 : 0;
            rowObject[report] = responsePercentage;
        })

        rowData.push(rowObject);
    })




    // let rowData = [
    //     { construct: 'test1', topic: 'waardering', question: "xx", score:Math.random()  },
    //     { construct: 'test2', topic: 'waardering', question: "xx", score:Math.random()  },
    //     { construct: 'test3', topic: 'waardering', question: "xx", score:Math.random()  },
    // ]

    return [columnDefs, rowData];
}