
import {
	allReportDataForFetchedGroups,
	availableReports,
	dashboardFocusReportSlug
} from '../stores/dataStore.ts';

import { get } from 'svelte/store';
import { currentLanguage } from '../stores/languageStore.js';

export function getDisplayReportName(slug) {
	//console.log("getDisplayReportName", slug);

	let allReports = get(availableReports); // Get the current value of the availableReports store
	//console.log("allReports", allReports);

	// Find the report object that matches the given slug
	let reportObject = allReports.find(report => report.slug === slug);

	// Check if a report with the given slug was found and return its name, otherwise return a default string or handle the case as needed
	let reportName = reportObject ? reportObject.name : 'Report not found';
	return reportName;
}

export function getAllKeysForGroupAndSubgroups(organizationalUnitsSelected, subGroupsSelected) {
	let arrayWithGroupKeys = [];
	let keysGroups = Object.keys(organizationalUnitsSelected);
	keysGroups.forEach((key) => {
		organizationalUnitsSelected[key].forEach((group) => {
			arrayWithGroupKeys.push(group.value);
		});
	});

	let arrayWithSubGroupKeys = [];
	let keysSubgroups = Object.keys(subGroupsSelected);
	keysSubgroups.forEach((key) => {
		subGroupsSelected[key].forEach((subgoup) => {
			arrayWithSubGroupKeys.push(subgoup.value);
		});
	});

	let arrayWithAllKeys = [];

	arrayWithGroupKeys.forEach((groupKey) => {
		arrayWithAllKeys.push(groupKey);
		arrayWithSubGroupKeys.forEach((subgroupKey) => {
			arrayWithAllKeys.push(groupKey + '_AND_' + subgroupKey);
		});
	})

	return arrayWithAllKeys;
}


export function scaleOrginalValueToDesiredScale(questionID: string, value: number) {
	// Check for null or undefined inputs
	//console.log('check', questionID, value)
	//console.log(questionData)
	if (questionID === null || value === null) {
		//	console.error("Invalid input: questionID or value is missing", questionID, questionData);
		return null;
	}
	let mean = value;

	let scaleDescription = getQuestionData(questionID);
	//console.log("scaleDescription", scaleDescription)

	// Ensure scaleDescription and its necessary nested data is not missing
	if (!scaleDescription || !scaleDescription.responseType || !scaleDescription.responseType.type) {
		// console.error("Invalid question data received for questionID: ", questionID);
		return null;
	}


	if (scaleDescription.responseType.type === 'LIKERT_7_POS' || scaleDescription.responseType.type === 'LIKERT_7_NEG' || scaleDescription.responseType.type === 'CHOICE_LIKERT_7_NA') {
		// Check value is a number and perform scaling
		if (typeof mean === 'number') {
			mean = (mean * 10) / 7;
			return mean;
		} else {
			console.error("Invalid input: value is not a number");
			return null;
		}
	} else if (scaleDescription.responseType.type.startsWith('CHOICE_')) {
		// console.warn("CHOICE QUESTION", questionID, mean, questionData.count);
		// console.log(mean)
		return 9999
	} else {
		console.warn("Other scale than LIKERT_7_POS/NEG CHOICE is currently not supported: ", questionID);
		return null;
	}
}

export function scaleValueToPercentage(questionID: string, value: number) {
	// Check for null or undefined inputs
	if (questionID == null || value == null) {
		// console.error("Invalid input: questionID or value is missing", questionID, value);
		return null;
	}

	let scaleDescription = getQuestionData(questionID);

	// Ensure scaleDescription and its necessary nested data is not missing
	if (!scaleDescription || !scaleDescription.responseType || !scaleDescription.responseType.type) {
		// console.error("Invalid question data received for questionID: ", questionID);
		return null;
	}

	if (scaleDescription.responseType.type === 'LIKERT_7_POS' || scaleDescription.responseType.type === 'CHOICE_LIKERT_7_NA' || scaleDescription.responseType.type === 'CHOICE_GRADE_10') {
		const valueMin = 5.5;
		const valueMax = 9.5;
		const scaleMin = 0;
		const scaleMax = 100;

		// Check value is a number before performing calculations
		if (typeof value === 'number') {
			if (value < valueMin) {
				console.warn(`Value is below the expected minimum range: ${value}. It has been set to ${valueMin}`);
				value = valueMin + 0.01;
			} else if (value > valueMax) {
				console.warn(`Value is above the expected maximum range: ${value}. It has been set to ${valueMax}`);
				value = valueMax;
			}
			let returnValue = ((value - valueMin) / (valueMax - valueMin)) * (scaleMax - scaleMin) + scaleMin;
			//console.log("returnValue", returnValue)
			return returnValue;
		} else {
			//console.error("Invalid input: value is not a number");
			return null;
		}
	} else {
		//console.warn("Other scale than LIKERT_7_POS is currently not supported: ", questionID);
		return null;
	}
}


export function getQuestionData(questionID) {
	if (typeof questionID !== 'string' || questionID.trim() === '') {
		console.error('Invalid questionID provided.');
		return null;
	}

	let allReportData = get(allReportDataForFetchedGroups);
	//console.log(allReportData);

	if (!allReportData || typeof allReportData !== 'object') {
		console.error('Report data is not available or in an invalid format.');
		return null;
	}

	let dashboardFocusReportSlugLocal = get(dashboardFocusReportSlug);
	if (!allReportData.hasOwnProperty(dashboardFocusReportSlugLocal)) {
		console.error(`Report slug ${dashboardFocusReportSlugLocal} not found in report data.`);
		return null;
	}

	let reportDetails = allReportData[dashboardFocusReportSlugLocal]['details'];
	//console.log(reportDetails);

	if (!reportDetails || typeof reportDetails !== 'object') {
		console.error('Report details are not available or in an invalid format.');
		return null;
	}

	let constructs = reportDetails.constructs;
	if (!constructs || typeof constructs !== 'object') {
		console.error('Constructs data is not available or in an invalid format.');
		return null;
	}

	let constructKeys = Object.keys(constructs);
	for (let constructKey of constructKeys) {
		let construct = constructs[constructKey];

		if (!construct || typeof construct !== 'object' || !construct.hasOwnProperty('topics')) {
			console.warn(`Missing or invalid topics for construct: ${constructKey}`);
			continue;
		}

		let topicKeys = Object.keys(construct['topics']);
		for (let topicKey of topicKeys) {
			let topic = construct['topics'][topicKey];

			if (!topic || typeof topic !== 'object' || !topic.hasOwnProperty('items')) {
				console.warn(`Missing or invalid items for topic: ${topicKey}`);
				continue;
			}

			if (topic['items'].hasOwnProperty(questionID) && topic['id'] !== "results.nes") {
				//console.log(topic)
				return topic['items'][questionID];
			}
		}
	}

	//console.log(`QuestionID ${questionID} not found in any constructs.`);
	return null;
}


export function getDataForSpecificGroup(slugs, groupID, itemID) {
	let allData = get(allReportDataForFetchedGroups);
	let dataToReturn = {};

	//console.log("getDataForSpecificGroup", slugs, groupID, itemID)
	//console.log("allData", allData)

	slugs.forEach(slug => {
		if (allData.hasOwnProperty(slug)) {
			if (allData[slug].hasOwnProperty(groupID)) {
				if (allData[slug][groupID] && allData[slug][groupID].hasOwnProperty(itemID)) {
					let data = allData[slug][groupID][itemID];
					dataToReturn[slug] = data;
				} else {
					console.warn(`ItemID '${itemID}' not found in groupID '${groupID}' for slug '${slug}'`);
					dataToReturn[slug] = null;
				}
			} else {
				console.warn(`GroupID '${groupID}' not found for slug '${slug}'`);
				dataToReturn[slug] = null;
			}
		} else {
			console.warn(`Slug '${slug}' not found in data`);
			dataToReturn[slug] = null;
		}
	});

	return dataToReturn;
}



export function getGroupNameForKey(groupKey) {

	//	console.log("getGroupAndSubgroupNameForKey", groupKey)

	if (typeof groupKey !== 'string' || groupKey.trim() === '') {
		console.error('Invalid key provided.');
		return null;
	}

	let allReportData = get(allReportDataForFetchedGroups);

	if (!allReportData || typeof allReportData !== 'object') {
		console.error('Report data is not available or in an invalid format.');
		return null;
	}

	let dashboardFocusReportSlugLocal = get(dashboardFocusReportSlug);
	if (!allReportData.hasOwnProperty(dashboardFocusReportSlugLocal)) {
		console.error(`Report slug ${dashboardFocusReportSlugLocal} not found in report data.`);
		return null;
	}

	let reportDetails = allReportData[dashboardFocusReportSlugLocal]['details'];
	//console.log(reportDetails);

	if (!reportDetails || typeof reportDetails !== 'object') {
		console.error('Report details are not available or in an invalid format.');
		return null;
	}

	let groupsObject = reportDetails.groups;
	if (!groupsObject || typeof groupsObject !== 'object') {
		console.error('groupsObject is not available or in an invalid format.');
		return null;
	}
	let subgroupsObject = reportDetails.subgroups;
	if (!subgroupsObject || typeof subgroupsObject !== 'object') {
		console.error('Groups data is not available or in an invalid format.');
		return null;
	}

	//check first if groupKey contains _AND_ if so and split it
	let nameToReturn = null;
	let languageToShow = get(currentLanguage)

	let groupKeySplit = groupKey.split('_AND_');

	//console.log("groupKeySplit", groupKeySplit)
	if (groupKeySplit.length > 1) {
		let groupname = findGroupName(groupKeySplit[0]);
		let subgoupname = findSubGroupName(groupKeySplit[1]);
		nameToReturn = groupname[languageToShow.code] + ' - ' + subgoupname[languageToShow.code];

	} else {
		let groupname = findGroupName(groupKey);
		let subgroupname = null;
		//console.log("groupname", groupname)

		// Check if groupname and groupname[languageToShow.code] exist
		if (groupname && groupname[languageToShow.code]) {
			nameToReturn = groupname[languageToShow.code];
		} else {
			// Provide a fallback value or handle the undefined case
			nameToReturn = "Name X"; // Replace with an appropriate fallback
		}
	}
	return nameToReturn;

	function findGroupName(groupKeyToSearch) {
		let mainGroupKeys = Object.keys(groupsObject);
		for (let mainGroupKey of mainGroupKeys) {
			let result = searchGroup(groupsObject[mainGroupKey], groupKeyToSearch);
			if (result !== null) {
				return result;
			}
		}
		return null;
	}
	function findSubGroupName(groupKeyToSearch) {
		let mainGroupKeys = Object.keys(subgroupsObject);
		for (let mainGroupKey of mainGroupKeys) {
			let result = searchGroup(subgroupsObject[mainGroupKey], groupKeyToSearch);
			if (result !== null) {
				return result;
			}
		}
		return null;
	}

	function searchGroup(group, groupKeyToSearch) {

		//console.log("searchGroup",group['slug'], groupKeyToSearch)
		if (!group || typeof group !== 'object') {
			console.error('Group data is not available or in an invalid format.');
			return null;
		}
		//console.log(group.slug===groupKeyToSearch)

		if (group.slug === groupKeyToSearch) {
			return group.name;
		}

		if (group.hasOwnProperty('children')) {
			let groupKeys = Object.keys(group['children']);
			for (let groupKey of groupKeys) {
				let result = searchGroup(group['children'][groupKey], groupKeyToSearch);
				if (result !== null) {
					return result;
				}
			}
		}

		return null;
	}





}

export function createIDStringForElement(key: string) {
	// Remove capital letters
	let idString = key.toLowerCase();

	// Remove spaces and non-alphanumeric characters
	idString = idString.replace(/\W+/g, '');

	return 'id_' + idString;
}